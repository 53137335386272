'use strict';
app.controller('homeController', ['$ocLazyLoad', '$rootScope', '$scope', 'authService', 'DASHBOARDCONSTANT', 'customLocalStorageService', 'localStorageService', 'controlService', 'displayService', '$state', '$http', '$q', '$sce', '$attrs', '$cookies', 'broadcastService', function ($ocLazyLoad, $rootScope, $scope, authService, DASHBOARDCONSTANT, customLocalStorageService, localStorageService, controlService, displayService, $state, $http, $q, $sce, $attrs, $cookies, broadcastService)
{
    //redirect back to dashboard if user logged in
    if (!authService.authentication.isAuth)
    {
        $state.go('/login');
    }

    $scope.dBoardCatList = [];
    $scope.componentList = [];
    $scope.tempList = [];
    $scope.counter = 0;

    $scope.dataItemDelete = "";
    $scope.dataItemIsDelete = false;
    $scope.dataItem = "";
    let dashboardType = "Dashboard";
    let isSaving = false;
    const getIsQuartoDashboard = () => dashboardType === "Dashboard";
    const getIsQuartoConnectDashboard = () => dashboardType === "Connect";

    $scope.setDisable = function ()
    {
        angular.forEach($scope.dBoardCatList, function (parentData, index)
        {
            angular.forEach(parentData.dBoardDatas, function (element, index)
            {
                element.IsDisabled = false;
            })
        })

        angular.forEach($scope.componentList, function (key, value)
        {
            angular.forEach($scope.dBoardCatList, function (parentData, index)
            {
                angular.forEach(parentData.dBoardDatas, function (data, index)
                {
                    if (data.DBMKey == key.DBMKey)
                    {
                        data.IsDisabled = "DisabledComp";
                    }
                })
            })
        });
    };

    $scope.saveHomeDBEdit = function ()
    {
        $rootScope.$broadcast("dashboardsave", {});
    };

    $scope.cancelHomeDBEdit = function () {
        $rootScope.$broadcast("refreshDisplayInfo", {});
        $rootScope.$broadcast("dashboardclose", {});
    };

    $scope.addToComponent = function ({ UniqueName, JsControllerUrl, Attribute, DBWidth, DBHeight, DBUrl, SeqNo, DBoardCompKey, DBMKey, DBoardCatKey, DBoardCatDesc })
    {
        const attributesWithValidateRefreshBtn = ['lm-recent-item', 'lm-last-login', 'lm-weather', 'lm-pending-approval', 'lm-Gst-In-Out', 'lm-Account-Payable-Aging', 'lm-Account-Receivable-Aging',
            'lm-ma-cost-tonne-chart', 'lm-yield-ou-chart', 'lm-ma-cost-hect-chart', 'lm-ma-expenditure-chart', 'lm-yield-hect-chart', 'lm-mth-end-close', 'lm-daily-ffb-cpo' ]
        const GotRefresh = attributesWithValidateRefreshBtn.includes(Attribute);
        return new Promise(function (resolve, reject)
        {
            if ($ocLazyLoad.isLoaded(UniqueName))
            {
                $scope.componentList.push({
                    id: UniqueName,
                    componentType: [
                        { attr: Attribute, value: "" },
                        { attr: "t-url", value: "\"" + DBUrl + "\"" }
                    ],
                    seqNo: (SeqNo),
                    widthSize: DBWidth,
                    DBoardCompKey,
                    DBMKey,
                    GotRefresh
                });

                resolve();
            }
            else
            {
                $ocLazyLoad.load({
                    name: UniqueName,
                    files: [JsControllerUrl]
                }).then(function (success)
                {
                    $scope.componentList.push({
                        id: UniqueName,
                        componentType: [
                            { attr: Attribute, value: "" },
                            { attr: "t-url", value: "\"" + DBUrl + "\"" }
                        ],
                        seqNo: (SeqNo),
                        widthSize: DBWidth,
                        DBoardCompKey,
                        DBMKey,
                        GotRefresh
                    });

                    resolve();

                    //if ($scope.$root != null && $scope.$root != undefined) { if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') $scope.$apply() };
                });
            }
        });
    }

    $scope.dbCompDataSource = new kendo.data.DataSource({
        type: "odata",
        transport: {
            read:
            {
                url: () => $rootScope.masterOdataUrl + `DBoardCompMenu?$filter=(IsMobile+eq+${getIsQuartoConnectDashboard()})`,
                jsonp: false,
                complete: function (e) {
                    $scope.$apply(() => {
                        $scope.setDisable();
                        $scope.GenerateDBoardCompList(e.responseJSON.value);
                    });
                },
                dataType: "json"
            }
        },
        schema: {
            data: function (data)
            {
                return broadcastService.parseStringToDecimal(data["value"]);
            },
            total: function (data)
            {
                return data["odata.count"];
            },
            model: {
                id: "DBMKey",
                fields: {
                    DBoardCompKey: { type: "int", editable: false, nullable: false },
                    DBoardCompCode: { type: "string", editable: true, nullable: false },
                    DBoardCompDesc: { type: "string", editable: true, nullable: false },
                    SeqNo: { type: "int", editable: false, nullable: false },
                    DBWidth: { type: "int", editable: false, nullable: false },
                    DBHeight: { type: "int", editable: false, nullable: false },

                    DBUrl: { type: "string", editable: false, nullable: false },
                    JsControllerUrl: { type: "string", editable: false, nullable: false },
                    DBoardIconUrl: { type: "string", editable: false, nullable: false },
                    Attribute: { type: "string", editable: false, nullable: false },
                    UniqueName: { type: "string", editable: false, nullable: false },
                    DBoardCompCodeDBoardCompDesc: { type: "string", editable: false, nullable: false },
                    DBMKey: { type: "int", editable: false, nullable: false },
                    GotRefresh: { type: "boolean", editable: false, value: false },
                    DBoardCatKey: { type: "int", editable: false, value: false },
                    DBoardCatDesc: { type: "string", editable: false, value: false },
                }
            }
        },
        sort: [{ field: "DBoardCatDesc", dir: "asc" }, { field: "DBoardCompDesc", dir: "asc" }]
    });

    $scope.dbCompDataSource.read();

    function orderdBoardCatListSequence(DBoardCatKey) {
        const GENERAL = 1, ACCOUNT = 2, CHECKROLL = 3, MILL = 4;
        const QCONNECT = 5, ESTATE = 6, BUDGET = 7, GWINSP = 8;

        switch (DBoardCatKey) {
            case ESTATE:
                return 1;
            case MILL:
                return 2;
            case ACCOUNT:
                return 3;
            case BUDGET:
                return 4;
            case GENERAL:
                return 5;
            case CHECKROLL:
                return 6;
            case QCONNECT:
                return 7;
            case GWINSP:
                return 8;
            default:
                return 9;
        }
    }

    $scope.GenerateDBoardCompList = function (data)
    {
        data.forEach(function (element)
        {
            let exist = false;
            let index = -1;

            if ($scope.dBoardCatList.length > 0)
            {
                for (var i = 0; i < $scope.dBoardCatList.length; i++)
                {
                    if ($scope.dBoardCatList[i].DBoardCatKey == element.DBoardCatKey)
                    {
                        exist = true;
                        index = i;
                    }
                }
            }

            const boardCatListSeq = orderdBoardCatListSequence(element.DBoardCatKey);
            if (exist && index > -1)
            {
                $scope.dBoardCatList[index].dBoardDatas.push({
                    UniqueName: element.UniqueName,
                    JsControllerUrl: element.JsControllerUrl,
                    Attribute: element.Attribute,
                    DBWidth: element.DBWidth,
                    DBHeight: element.DBHeight,
                    DBUrl: element.DBUrl,
                    seqNo: 0,
                    DBoardCompKey: element.DBoardCompKey,
                    DBoardCompDesc: element.DBoardCompDesc,
                    DBoardIconUrl: element.DBoardIconUrl,
                    DBMKey: element.DBMKey,
                    DBoardCatKey: element.DBoardCatKey,
                    DBoardCatDesc: element.DBoardCatDesc,
                    Active: false,
                    IsDisabled: false,
                    BoardCatListSequence: boardCatListSeq
                });
            }
            else
            {
                $scope.dBoardCatList.push({
                    dBoardDatas: [{
                        UniqueName: element.UniqueName,
                        JsControllerUrl: element.JsControllerUrl,
                        Attribute: element.Attribute,
                        DBWidth: element.DBWidth,
                        DBHeight: element.DBHeight,
                        DBUrl: element.DBUrl,
                        seqNo: 0,
                        DBoardCompKey: element.DBoardCompKey,
                        DBoardCompDesc: element.DBoardCompDesc,
                        DBoardIconUrl: element.DBoardIconUrl,
                        DBMKey: element.DBMKey,
                        DBoardCatKey: element.DBoardCatKey,
                        DBoardCatDesc: element.DBoardCatDesc,
                        IsDisabled: false,
                    }],
                    DBoardCatKey: element.DBoardCatKey,
                    DBoardCatDesc: element.DBoardCatDesc,
                    Active: false,
                    BoardCatListSequence: boardCatListSeq
                });
            }
        })
    }

    $scope.setReportActive = function (dBoardCat)
    {
        dBoardCat.active = !dBoardCat.active;
        if (dBoardCat.active)
            $("#homeDbDraggableCat" + dBoardCat.DBoardCatKey).addClass("in");
        else
            $("#homeDbDraggableCat" + dBoardCat.DBoardCatKey).removeClass("in");
    }

    $scope.$on("StartLoadingDashboard", async function (event, { onDashboardLoaded }) {
        //loadComponents
        const components = (await displayService.getDashboardInformation()).map((item) => $scope.addToComponent(item));
        $.when(...components).then(function () {
            $scope.$apply(() => {
                $scope.setDisable();
                if (_.isFunction(onDashboardLoaded))
                    onDashboardLoaded();
            });
        });
    });

    $scope.$on("ChangeDashboard", function (event, opt) {
        //$scope.dBoardCatList = [];
        if (dashboardType !== opt.dashboardTitle) {
            dashboardType = opt.dashboardTitle;
            //$scope.dbCompDataSource.read();
            $rootScope.$broadcast("refreshDisplayInfo", {});
        }
    });

    $scope.removeComponent = function (index)
    {
        $scope.counter = $scope.counter - 1;
        $scope.componentList.sort(function (a, b) { return parseFloat(a.seqNo) - parseFloat(b.seqNo) });
        $scope.componentList.splice(index, 1);
        $scope.setDisable();
    };

    $scope.reloadDBComponent = function ()
    {
        //console.log('reloadDBComponent triggered!');
        //using counter to drag whether already refresh one round
        $scope.counter = $scope.counter + 1;

        if ($scope.counter <= 1)
        {
            $scope.tempList = [];
            for (var i = 0; i < $scope.componentList.length; i++)
            {
                $scope.tempList.push($scope.componentList[i]);
            }

            $rootScope.$broadcast(DASHBOARDCONSTANT.EVENT.REFRESH_WIDGET, {});
        }
        else
        {
            for (var i = 0; i < $scope.componentList.length; i++)    
            {
                let found = false;
                //check the array consist of all widget for difference
                //ignore for sequence , only check for whether there is new widget
                for (var j = 0; j < $scope.tempList.length; j++)     
                {
                    if ($scope.componentList[i].id == $scope.tempList[j].id)
                    {
                        found = true;
                        break;
                    }
                }

                if (found == false)
                {
                    reloadSpecificDBComp($scope.componentList[i].id);
                }
            }

            $scope.tempList = [];
            for (var i = 0; i < $scope.componentList.length; i++)
            {
                $scope.tempList.push($scope.componentList[i]);
            }
        }
    }

    function reloadSpecificDBComp(name)
    {
        //console.log('reloadSpecificDBComp(' + name + ') triggered!');
        $rootScope.$broadcast(DASHBOARDCONSTANT.EVENT.REFRESH_WIDGET_CUSTOM, name);
    }

    function dashboardSwitchMode(mode)
    {
        if (typeof mode === "string")
        {
            if (mode === "edit")
            {
                $rootScope.dashboardchanged = true;

                $('#homeDbSortable').css({
                    'left': ''
                });
                $("#homeDbSortable").kendoSortable({
                    autoScroll: true,
                    handler: "h3",
                    placeholder: function placeholder(element) {
                        return element.clone().css("opacity", 0.5);
                    },
                    hint: function (element) {
                        return element.clone().removeClass("k-state-selected");
                    },
                    start: function (e) {
                        if (!$rootScope.dashboardchanged) {
                            e.preventDefault();
                        }
                        $scope.dataItemDelete = "";
                        $scope.dataItemIsDelete = false;

                        angular.forEach($scope.componentList, function (key, value) {
                            if (e.item.data().$scope.comp.$$hashKey == key.$$hashKey) {
                                $scope.dataItemDelete = key;
                            }
                        });
                    },
                    change: function (e) {
                        if ($scope.dataItemIsDelete == false) {
                            if (e.oldIndex != -1) {
                                $scope.componentList[e.oldIndex].seqNo = $scope.componentList[e.newIndex].seqNo;

                                if (e.newIndex > e.oldIndex) {
                                    for (var i = e.oldIndex + 1; i <= e.newIndex; i++) {
                                        $scope.componentList[i].seqNo--;
                                    }
                                }
                                else {
                                    for (var i = e.oldIndex - 1; i >= e.newIndex; i--) {
                                        $scope.componentList[i].seqNo++;
                                    }
                                }

                                $scope.componentList.sort(function (a, b) { return parseFloat(a.seqNo) - parseFloat(b.seqNo) });
                                $scope.setDisable();

                                if ($scope.$root != null && $scope.$root != undefined) { if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') $scope.$apply() };
                            }
                        }
                    },
                    end: function (e) {
                        if (e.action === "receive") {
                            let divId = e.item[0].id; // Get div id
                            let dbmValue = divId.slice(10); //Get DBMKey from div id

                            $scope.dataItemDelete = "";
                            $scope.dataItem = "";
                            $scope.dataItemIsDelete = false;

                            for (var i = 0; i < $scope.dBoardCatList.length; i++) {
                                $scope.dataItem = $scope.dBoardCatList[i].dBoardDatas.find(item => item.DBMKey === parseInt(dbmValue));
                                if (typeof $scope.dataItem !== "undefined" && $scope.dataItem !== "")
                                    break;
                            }

                            for (var i = e.newIndex; i < $scope.componentList.length; i++) {
                                $scope.componentList[i].seqNo++;
                            }

                            const componentParam = { ...$scope.dataItem, SeqNo: e.newIndex + 1 };
                            $scope.addToComponent(componentParam).then(function () {
                                $scope.setDisable();
                                $scope.componentList.sort(function (a, b) { return parseFloat(a.seqNo) - parseFloat(b.seqNo) });
                            });

                            $scope.setDisable();

                            dashboardSwitchMode("edit");

                            e.preventDefault();
                        }
                        else {
                            if ($scope.dataItemIsDelete == true) {
                                e.preventDefault();
                                let tempInt = 0;
                                for (var j = 0; j < $scope.componentList.length; j++) {
                                    if ($scope.dataItemDelete.$$hashKey == $scope.componentList[j].$$hashKey) {
                                        $scope.componentList.splice(j, 1);
                                        tempInt = j;
                                    }
                                }

                                var tempComp = $scope.componentList;

                                $scope.componentList = [];
                                $scope.componentList = tempComp;

                                for (var i = tempInt; i < $scope.componentList.length; i++) {
                                    $scope.componentList[i].seqNo--;
                                }

                                $scope.componentList.sort(function (a, b) { return parseFloat(a.seqNo) - parseFloat(b.seqNo) });
                            }

                            dashboardSwitchMode("edit");
                        }
                    }
                });
                if ($scope.$root != null && $scope.$root != undefined) { if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') $scope.$apply() };
            }
            else if (mode === "save" || mode === "close")
            {
                $rootScope.dashboardchanged = false;

                $("#homeDbSortable").kendoSortable({ disabled: "color: #FF0000;"});
                $('#homeDbSortable').css({
                    'left': '0px'
                });

                if ($scope.$root != null && $scope.$root != undefined) { if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') $scope.$apply() };
            }
        }
    }

    $scope.$on("dashboardedit", function ()
    {
        dashboardSwitchMode("edit");
    });

    $scope.$on("dashboardclose", function () {
        dashboardSwitchMode("close");
    });

    $scope.$on("dashboardsave", function ()
    {
        if (isSaving === false)
        {
            kendo.ui.progress($("div#homepage"), true);

            $http({
                url: $rootScope.dashboardOdataUrl + "DBoardPrefHeader",
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            }).then(function (prefer_data)
            {
                let data = {
                    DBoardKey: 0,
                    DBoardCode: "",
                    DBoardDesc: "",
                    CreatedBy: -1,
                    CreatedDate: (new Date(Date.now())),
                    UpdatedBy: -1,
                    UpdatedDate: (new Date(Date.now())),
                    SeqNo: 1,
                    Active: 1
                };

                if (prefer_data && prefer_data.data && prefer_data.data.value && prefer_data.data.value.length && prefer_data.data.value.length > 0)
                {
                    data = {
                        DBoardKey: +prefer_data.data.value[0].DBoardKey,
                        DBoardCode: prefer_data.data.value[0].DBoardCode,
                        DBoardDesc: prefer_data.data.value[0].DBoardDesc,
                        CreatedBy: +prefer_data.data.value[0].CreatedBy,
                        CreatedDate: prefer_data.data.value[0].CreatedDate,
                        UpdatedBy: +prefer_data.data.value[0].UpdatedBy,
                        UpdatedDate: prefer_data.data.value[0].UpdatedDate,
                        SeqNo: 1,
                        Active: 1
                    };
                }

                $.extend(data, { dboardprefdets: $scope.componentList });
                if (dashboardType == "Dashboard")
                {
                    $http({
                        url: $rootScope.dashboardWebApiurl + "DBoardPref",
                        method: "POST",
                        data: data,
                        headers: { 'Content-Type': 'application/json' }
                    }).then(function ()
                    {
                        //refresh
                        displayService.refresh().then(function () { return true; }, function () { return false; }).then(function ()
                        {
                            isSaving = false;
                            kendo.ui.progress($("div#homepage"), false);
                            dashboardSwitchMode("save");

                            $rootScope.$broadcast(DASHBOARDCONSTANT.EVENT.REFRESH_WIDGET, {});
                        });
                    }, function ()
                    {
                        //refresh
                        displayService.refresh().then(function () { return true; }, function () { return false; }).then(function ()
                        {
                            isSaving = false;
                            kendo.ui.progress($("div#homepage"), false);
                            dashboardSwitchMode("save");
                            console.log("Dashboard changes save failed.");
                        });
                    });
                }
                else
                {
                    $http({
                        url: $rootScope.dashboardWebApiurl + "QCDBoardPref",
                        method: "POST",
                        data: $scope.componentList,
                        headers: { 'Content-Type': 'application/json' }
                    }).then(function ()
                    {
                        //refresh
                        displayService.refresh().then(function () { return true; }, function () { return false; }).then(function ()
                        {
                            isSaving = false;
                            kendo.ui.progress($("div#homepage"), false);
                            dashboardSwitchMode("save");

                            $rootScope.$broadcast(DASHBOARDCONSTANT.EVENT.REFRESH_QC_WIDGET, {});
                        });
                    }, function ()
                    {
                        isSaving = false;
                        kendo.ui.progress($("div#homepage"), false);
                        dashboardSwitchMode("save");
                        console.log("QC Dashboard changes save failed.");
                    });
                }
            }, function ()
            {
                isSaving = false;
                kendo.ui.progress($("div#homepage"), false);
                dashboardSwitchMode("save");
                console.log("Dashboard changes save failed.");
            });
        }
    });
    
    const dashboardRequestsDebouncer = lm.createDebouncer();

    $scope.$on("refreshDisplayInfo", function (data)
    {
        dashboardRequestsDebouncer.debounce(async function* () {
            $scope.$apply(() => {
                $scope.dBoardCatList = [];
                $scope.componentList = [];
                $scope.isRefreshingDisplayInfo = true;
            });

            yield await $scope.dbCompDataSource.read();

            const getDashboardService = getIsQuartoDashboard() ? displayService.getDashboardInformation : displayService.getQCDashboardInformation;
            const components = (await getDashboardService()).map((item) => $scope.addToComponent(item));

            yield await $.when(...components);

            $scope.$apply();
            $scope.setDisable();
            $rootScope.$broadcast(DASHBOARDCONSTANT.EVENT.REFRESH_WIDGET, {});
            $scope.isRefreshingDisplayInfo = false;
        });
    });

}]).directive('dboardCompDirectiveHome', function ()
{
    return {
        restrict: 'AE',
        template: '<div><img ng-src="{{dBoard.DBoardIconUrl}}" alt="{{dBoard.DBoardCompDesc}} image" /><h3>{{ dBoard.DBoardCompDesc }}</h3></div>',
        link: function (scope, element, attrs)
        {
            if (scope.$last)
            {
                scope.$emit('LastElem');
            }
        }
    }
}).directive('dboardItemDirectiveHome', function ()
{
    return {
        restrict: 'AE',
        link: function (scope, element, attrs)
        {
            //https://docs.angularjs.org/api/ng/directive/ngRepeat
            //widget added into last element
            if (scope.$last)
            {
                scope.reloadDBComponent();
            }
            //widget added as first element
            else if (scope.counter > 0 && scope.$first)
            {
                scope.reloadDBComponent();
            }
            //widget added into the middle
            else if (scope.counter > 0 && scope.$middle)
            {
                scope.reloadDBComponent();
            }
        }
    }
}).directive('dboardCatDirectiveHome', function ()
{
    return {
        link: function (scope, element, attrs)
        {
            scope.$on('LastElem', function (event)
            {
                var target = '#' + attrs.id;
                $(target).kendoSortable({
                    connectWith: "#homeDbSortable",
                    disabled: ".DisabledComp",
                    start: function (e)
                    {

                    },
                    hint: function (e)
                    {
                        e.css("visibility", "visible");
                        return e.clone().addClass("ob-hint");
                    }
                })

                scope.setDisable();
            });
        }
    }
});